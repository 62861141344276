<template>
  <v-form
    class="pt-1 campaign-form"
    lazy-validation
    ref="permissions_form"
    v-model="valid"
  >
    <v-breadcrumbs :items="items"></v-breadcrumbs>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="7">
        <general-card class="pa-5 mt-5 three-quarter-height">
          <h6 class="mb-2">Permission Name</h6>
          <v-text-field
            label="Permission Name"
            dense
            outlined
            class="mb-4"
            v-model="name"
            :rules="[(v) => !!v || 'Input permission name to continue!']"
          >
          </v-text-field>
          <v-select
            outlined
            dense
            label="Select Permission Type"
            v-model="tag_type"
            :rules="[(v) => !!v || 'Select tag type to continue!']"
            :items="tag_types_data"
            @change="clearTag()"
            class="mb-4"
          ></v-select>
          <v-combobox
            :items="tag_ids"
            label="Type to select permission tag"
            outlined
            dense
            item-text="name"
            item-value="id"
            :rules="[(v) => !!v || 'Select permission tag to continue!']"
            v-model="tag"
            class="mb-4"
          ></v-combobox>

          <v-card-actions class="d-flex justify-end">
            <v-btn
              color="primary"
              small
              @click="editPermission"
              v-if="$route.name == 'update-permission'"
            >
              Update
            </v-btn>
            <v-btn color="primary" small @click="addPermission" v-else
              >Submit</v-btn
            >
          </v-card-actions>
        </general-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import GeneralCard from "../../../components/Layouts/Cards/GeneralCard.vue";

export default {
  data: () => ({
    valid: true,
    name: "",
    tag_id: "",
    tag_type: "",
    tag: null,
    tag_types_data: ["client", "fb_ad_account"],
    items: [
      {
        text: "Permissions",
        disabled: false,
        href: "#/users-management/permissions",
      },
      {
        text: "Add Permission",
        disabled: true,
        href: "#",
      },
    ],
  }),
  computed: {
    single_permission() {
      return this.$store.state.permission;
    },
    clients() {
      if (!this.$store.state.clients.length) {
        this.getClients();
      }
      let arr = this.$store.state.clients;
      arr.forEach((item) => {
        this.renameClientName(item);
      });
      return arr;
    },
    fb_ad_accounts() {
      if (!this.$store.state.facebook.ad_accounts.length) {
        this.getFBAdAccounts();
      }
      return this.$store.state.facebook.ad_accounts;
    },
    tag_ids: {
      get() {
        let tags = this.tagsData(this.tag_type);
        return tags;
      },
      set() {},
    },
  },
  watch: {
    tag_type() {
      let tags = this.tagsData(this.tag_type);
      this.tag_ids = tags;
    },
    tag() {
      this.tag_id = this.tag ? this.tag.id : null;
    },
    single_permission() {
      let tag;
      this.tag_type = this.single_permission.tag_type;
      if (this.tag_type === "client") {
        tag = this.single_permission.client;
        this.renameClientName(tag);
      }
      if (this.tag_type === "fb_ad_account") {
        tag = this.single_permission.fb_ad_account;
      }
      this.tag = tag;
      this.name = this.single_permission.name;
      this.name = this.name.split(this.tag_type)[0].replace(/_/g, " "); // Hide part of name after tag_type and hide underscores
    },
  },
  created() {
    if (this.$route.name == "update-permission") {
      this.getPermissionData();
    }
  },
  components: {
    GeneralCard,
  },
  methods: {
    async addPermission() {
      await this.$refs.permissions_form.validate();
      if (!this.name || !this.valid) return;
      this.name = this.name.replace(/ /g, "_"); // Add underscores
      let body = {
        name: this.name,
        tag_type: this.tag_type,
        tag_id: this.tag_id,
      };
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("addNewPermission", body);
        this.$store.commit("makingApiRequest", false);

        this.$notify({
          title: "Permission added successfully!",
          group: "success",
        });

        this.$router.push("/users-management/permissions");
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
    renameClientName(tag) {
      tag.name = tag.client_name;
      delete tag.client_name;
    },
    tagsData(tag_type) {
      let tags;
      if (tag_type === "client") {
        tags = this.clients;
      }
      if (tag_type === "fb_ad_account") {
        tags = this.fb_ad_accounts;
      }
      return tags;
    },
    async editPermission() {
      if (!this.name) return;
      this.name = this.name.replace(/ /g, "_"); // Add underscores
      let combined_data = {
        permission_id: this.$route.params.id,
        body: {
          name: this.name,
          tag_type: this.tag_type,
          tag_id: this.tag_id,
        },
      };
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("updatePermission", combined_data);
        this.$store.commit("makingApiRequest", false);

        this.$notify({
          title: "Permission updated successfully!",
          group: "success",
        });

        this.$router.push("/users-management/permissions");
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
    async getPermissionData() {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch(
          "getSinglePermission",
          this.$route.params.id
        );
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
    // Get Adaccounts
    async getFBAdAccounts() {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("facebook/getFacebookAdaccounts");
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
    //Get Clients
    async getClients() {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("readClients");
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
    clearTag() {
      this.tag = null;
    },
  },
};
</script>